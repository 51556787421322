import axios from "axios";
import { api } from "../config";
import state from '../../store/store'
import get from 'lodash/get'
import CryptoJS from 'crypto-js';


interface authenticateUser {
  identifier: string;
  password: string;
}



const handleJWT = async (res)=>{

  const auth: any = state.auth;
  auth.jwt = res.data.jwt;
  
  // COULD: only set if 'remember me' set in login screen
  // store token
  if (!localStorage.getItem(state.cached.key)) {
    const salt = state.cached.tk;
    const exp = new Date().setHours(new Date().getHours() + state.cached.expHours);
    const data = {
      exp,
      tk: res.data.jwt,
    };
    localStorage.setItem(
      state.cached.key,
      CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString()
    );
  }


  // create authedAxios
  const authedAxios = axios.create();

  // Request interceptor for API calls
  authedAxios.interceptors.request.use(
    async (config) => {
      const jwt = get(state.auth, "jwt");
      config.headers = {
        Authorization: `Bearer ${jwt}`,
        Accept: "application/json",
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // Response interceptor for API calls
  authedAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (error.response.status === 403) {
        state.auth = {};
      }
      return Promise.reject(error);
    }
  );

  auth.authedAxios = authedAxios;
  const me = await state.auth["authedAxios"].get(`${api}/users/me`);
  auth.user = me.data;

  return auth;
}

const authenticate = async (data: authenticateUser) => {
  const res = await axios.post(`${api}/auth/local`, data);
  const auth = await handleJWT(res);
  return auth;
};

export { authenticate, handleJWT };
